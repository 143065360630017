var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',[(_vm.current.permissions.COSTS_WRITE)?_c('v-icon',{staticClass:"ml-3 mr-0 my-5",staticStyle:{"position":"absolute"},attrs:{"large":""},on:{"click":function($event){_vm.drawer = true}}},[_vm._v("mdi-menu")]):_vm._e(),_c('v-navigation-drawer',{attrs:{"absolute":"","bottom":"","temporary":"","width":"280"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"exact-path":"","to":{ name: 'CostsOverview', params: { tenant: _vm.current.path, snapshot: 'live' } }}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"primary lighten-1 white--text",attrs:{"inactive":""}},[_vm._v(" mdi-cash-multiple ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fremdkosten")]),_c('v-list-item-subtitle')],1)],1),_c('v-divider',{staticClass:"my-4"}),_vm._l((_vm.userVendors),function(vendor){return _c('v-list-item',{key:vendor.id,attrs:{"to":{
            name: 'SingleVendor',
            params: { tenant: _vm.current.path, id: vendor.id },
          }}},[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"primary lighten-1",attrs:{"dark":""}},[_vm._v(" mdi-receipt ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(vendor.name)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(vendor.type)+" "+_vm._s(vendor.account_number)+" ")])],1)],1)})],2)],1)],1),_c('router-view'),(_vm.current.permissions.COSTS_WRITE)?_c('v-btn',{staticClass:"fab-new",attrs:{"color":"primary","elevation":"4","fab":"","large":"","to":{
      name: 'SingleVendor',
      params: { tenant: _vm.current.path, id: 'new' },
    }}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }