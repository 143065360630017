<template>
  <v-layout>
    <v-icon
      v-if="current.permissions.COSTS_WRITE"
      @click="drawer = true"
      large
      class="ml-3 mr-0 my-5"
      style="position: absolute"
      >mdi-menu</v-icon
    >
    <v-navigation-drawer v-model="drawer" absolute bottom temporary width="280">
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            exact-path
            :to="{ name: 'CostsOverview', params: { tenant: current.path, snapshot: 'live' } }"
          >
            <v-list-item-avatar>
              <v-icon inactive class="primary lighten-1 white--text">
                mdi-cash-multiple
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Fremdkosten</v-list-item-title>

              <v-list-item-subtitle> </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

  <v-divider class="my-4"></v-divider>

          <v-list-item
            v-for="vendor in userVendors"
            :key="vendor.id"
            :to="{
              name: 'SingleVendor',
              params: { tenant: current.path, id: vendor.id },
            }"
          >
            <v-list-item-avatar>
              <v-icon class="primary lighten-1" dark> mdi-receipt </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="vendor.name"></v-list-item-title>

              <v-list-item-subtitle>
                {{ vendor.type }} {{ vendor.account_number }}
              </v-list-item-subtitle>
            </v-list-item-content>

           
          </v-list-item>



        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>
    <router-view />
    <v-btn
      v-if="current.permissions.COSTS_WRITE"
      color="primary"
      elevation="4"
      fab
      large
      :to="{
        name: 'SingleVendor',
        params: { tenant: current.path, id: 'new' },
      }"
      class="fab-new"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </v-layout>
</template>


<script>
import { trl } from "@/utils/strings";
import { mapGetters } from "vuex";
import { toLocale } from "@/utils/numbers";
import Costs from "@/components/Costs";

export default {
  data() {
    return {
      drawer: false,
      search: "",
      forecast: null,
    };
  },
  computed: {
    ...mapGetters(["current", "vendors", "userVendors", "me"]),
  },
  methods: {
    trl,
  },
  components: {
    Costs
  }
};
</script>

<style lang="scss" scoped>
::v-deep table .v-input__slot::before {
  border-style: none !important;
}

::v-deep table .v-text-field input {
  font-size: 14px;
  color: dodgerblue !important;
  // text-align:right;
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: rgba(255, 255, 255, 0.38);
}

::v-deep
  .theme--light.v-data-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: rgba(255, 255, 255, 0.87);
}

::v-deep table .v-select {
  font-size: inherit;
  color: dodgerblue;

  .v-select__selection {
    color: dodgerblue;
  }
}
</style>